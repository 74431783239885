import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  actionClearLocalStorage,
  actionClearScheduleArray,
  actionGetDisplayById,
  actionGetDisplays,
  actionSetScheduleToDisplays,
  actionUpdateDisplay,
  actionUpdateDisplayRedux,
  actionUpdateScheduledArray,
} from "../../store/display";

import { actionResetUserPW } from "../../store/winfo";

import ScreenLoader from "../../components/loaders/ScreenLoader";
import "../styles/Pages.scss";
import {
  barBackgroundColorOpt,
  barFontSizeOpt,
  barSizeOpt,
  barTypeOpt,
  displayOrientationOpt,
} from "../../utils/options";
import {
  actionDeleteLayout,
  actionGetLayouts,
  actionSetLayoutInitials,
  actionUpdateLayout,
} from "../../store/layouts";
import LayoutCard from "../../components/cards/LayoutCard";
import OnlyForWinfo from "../../components/Winfo/OnlyForWinfo";
import OnlyIfAdminMode from "../../components/Winfo/OnlyIfAdminMode";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormatDate } from "../../utils/dates";

import DeleteIcon from "@mui/icons-material/Delete";
import CopyIcon from "@mui/icons-material/ContentCopy";

const OneDisplay = ({ socket }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { displayId } = useParams();
  const { oneDisplay, displays } = useSelector((state) => state.display);
  const { layouts, loadingLayouts } = useSelector((state) => state.layout);
  const { DBcardSize } = useSelector((state) => state.DB);

  const { online } = useSelector((state) => state.organization);
  const { data: userData } = useSelector((state) => state.user);
  const { selectedOrganization } = useSelector((state) => state.winfo);

  const [showSceduled, setShowScheduled] = useState(false);
  const [selectedDisplays, setSelectedDisplays] = useState([]);
  const [overwriteSchedules, setOverwriteSchedules] = useState(false);
  const [selectedToCopy, setSelectedToCopy] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    return () => {
      console.log("***** RUN ****");
      dispatch(actionUpdateDisplayRedux());
      dispatch(actionSetLayoutInitials());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!oneDisplay) {
      const params = {
        id: displayId,
        orgID: selectedOrganization?._id,
      };
      console.log("ORG", selectedOrganization);
      dispatch(actionGetDisplayById(params));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneDisplay]);

  useEffect(() => {
    if (!displays || !displays?.length) {
      console.log("EFF", displays, oneDisplay);
      const params = {
        orgID: selectedOrganization?._id,
      };

      dispatch(actionGetDisplays(params));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displays]);

  const fetchLayouts = useCallback(() => {
    if (oneDisplay) {
      const params = {
        orientation: oneDisplay.orientation,
        orgID: selectedOrganization?._id,
      };
      dispatch(actionGetLayouts(params));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneDisplay]);

  useEffect(() => {
    fetchLayouts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneDisplay]);

  const editLayout = (id) => {
    navigate(`/layouts/${id}?redirectDisplay=${oneDisplay._id}`);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const data = {
      [name]: value,
    };

    dispatch(actionUpdateDisplayRedux(data));
  };

  const submitDisplay = () => {
    const data = {
      displayId: displayId,
      orientation: oneDisplay.orientation,
      name: oneDisplay.name,
      orgID: selectedOrganization?._id,
      socket,
    };

    dispatch(actionUpdateDisplay(data));
  };

  const submitDisplay2 = () => {
    const data = {
      displayId: displayId,
      bottomBar: oneDisplay.bottomBar,
      topBar: oneDisplay.topBar,
      socket: socket,
      reloadDB: true,
      orgID: selectedOrganization?._id,
    };

    dispatch(actionUpdateDisplay(data));
  };

  const submitUpdateLayout = (data) => {
    const content = {
      ...data,
      socket,
      updateLayouts: true,
      orgID: selectedOrganization?._id,
    };

    dispatch(actionUpdateLayout(content));
  };

  const handleBarChange = (e, field) => {
    const { checked, name, value } = e.target;

    const value2 = field === "use" ? checked : value;

    const data = {
      [name]: {
        ...oneDisplay[name],
        [field]: value2,
      },
    };

    dispatch(actionUpdateDisplayRedux(data));
  };

  const remoteLogOutDB = () => {
    const socketData = {
      to: [oneDisplay._id],
      type: "REMOTE_LOGOUT_DB",
    };

    socket?.emit("DB_ACTION", socketData);
  };

  const clearStorage = () => {
    const id = oneDisplay._id;
    dispatch(actionClearLocalStorage(id));
  };

  const askCardSize = () => {
    const socketData = {
      to: [oneDisplay._id],
      type: "ASK_CARD_SIZE",
      responseTo: [userData._id],
      organizationId: userData.organizationId,
    };

    socket?.emit("DB_ACTION", socketData);
  };

  const deleteSchedule = (index) => {
    let scheduled = [...oneDisplay?.scheduled];

    scheduled.splice(index, 1);

    dispatch(
      actionUpdateScheduledArray({
        orgID: selectedOrganization?._id,
        socket: socket,
        displayId,
        scheduledArray: scheduled,
      })
    );
  };

  const copySchedule = (e, el) => {
    setAnchorEl(e.currentTarget);
    setSelectedToCopy(el);

    console.log("copy", el);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // setClickedLayout(null);
  };

  const handleCheckDisplay = (dp) => {
    const alreadySelected = selectedDisplays.some((el) => el._id === dp._id);

    let temp = selectedDisplays;

    if (alreadySelected) {
      temp = temp.filter((el) => el._id !== dp._id);
    } else {
      temp = [...temp, { _id: dp._id, name: dp.name }];
    }

    setSelectedDisplays(temp);
  };

  const submitDisplays = () => {
    console.log("SUBMIT", selectedDisplays);
    const data = {
      displays: selectedDisplays,
      schedule: selectedToCopy,
      orgID: selectedOrganization?._id,
      overwriteSchedules,
      displayId: displayId,
    };
    dispatch(actionSetScheduleToDisplays(data));
    setAnchorEl(null);
    setSelectedDisplays([]);
  };

  if (!oneDisplay || loadingLayouts) {
    return <ScreenLoader />;
  }

  return (
    <div className="Page OneDisplay">
      <div className="MainContainer">
        <div className="div1">
          <div className="div1-left">
            <h3>Näyttö: "{oneDisplay.name}"</h3>
            <small>
              {oneDisplay.username && oneDisplay.username.length < 15
                ? oneDisplay.username
                : null}
            </small>
            <p>Suunta: {oneDisplay.orientation}</p>
          </div>

          <div className="div1-right">
            {online[displayId] ? (
              <div className="statusBox">
                <div className="dot-online" />
                <p>ONLINE {`(${online[displayId]?.length})`}</p>
              </div>
            ) : (
              <div className="statusBox">
                <div className="dot-offline" />
                <p>OFFLINE</p>
              </div>
            )}
            <Button
              variant="outlined"
              color="primary"
              //onClick={() => setOpenPreview((prev) => !prev)}
              disabled
            >
              Esikatselu
            </Button>
          </div>
        </div>

        <div className="div2">
          <OnlyForWinfo>
            <OnlyIfAdminMode>
              <div className="basicInfoDiv ">
                <div className="innerBasicDiv SmallShadow">
                  <h2>* WINFO *</h2>

                  <TextField
                    label="PW"
                    type="text"
                    value={oneDisplay?.passwordString}
                    disabled
                    helperText="Uusi salasana vaatii sivun päivityksen"
                  />

                  <div className="buttonsDiv">
                    <div>
                      <Button
                        variant="contained"
                        color="onlyWinfo"
                        onClick={() =>
                          dispatch(
                            actionResetUserPW(
                              oneDisplay?.username,
                              socket,
                              true
                            )
                          )
                        }
                        fullWidth
                      >
                        Palauta salasana
                      </Button>
                    </div>

                    <div>
                      <Button
                        variant="contained"
                        color="onlyWinfo"
                        onClick={() => dispatch(remoteLogOutDB())}
                        fullWidth
                      >
                        Kirjauta ulos
                      </Button>
                    </div>

                    <div>
                      <Button
                        variant="contained"
                        color="onlyWinfo"
                        onClick={() => clearStorage()}
                        fullWidth
                      >
                        Tyhjennä muisti
                      </Button>
                    </div>

                    <div>
                      <Button
                        variant="contained"
                        color="onlyWinfo"
                        onClick={() => askCardSize()}
                        fullWidth
                      >
                        Kortin koko
                      </Button>
                      {DBcardSize && <small>KORTIN KOKO: {DBcardSize}</small>}
                    </div>
                  </div>
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      label="Nimi"
                      variant="outlined"
                      onChange={handleChange}
                      value={oneDisplay.name || "VIRHE"}
                      name="name"
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Kierto
                    </InputLabel>
                    <Select
                      value={oneDisplay?.orientation || 0}
                      label="Kierto"
                      onChange={handleChange}
                      name="orientation"
                    >
                      {displayOrientationOpt.map((el, i) => (
                        <MenuItem key={i} value={el.value}>
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => submitDisplay()}
                    fullWidth
                  >
                    Tallenna
                  </Button>
                </div>
              </div>
            </OnlyIfAdminMode>
          </OnlyForWinfo>

          <p>Näytetään vain sopivat asettelut</p>
          <div className="layoutsDiv">
            <div className="layouts">
              {loadingLayouts ? (
                <CircularProgress color="inherit" />
              ) : (
                layouts?.map((el, i) => (
                  <LayoutCard
                    item={el}
                    key={i}
                    //onClick={() => redirect(el._id)}
                    deleteLayout={(layoutId) =>
                      dispatch(
                        actionDeleteLayout({
                          layoutId,
                          orgID: selectedOrganization?._id,
                        })
                      )
                    }
                    removeDisplays={() => console.log("Remove db")}
                    selectedDPId={displayId}
                    selectedDPName={oneDisplay?.name}
                    onClick={(id) => editLayout(id)}
                    displays={displays}
                    submitUpdateLayout={submitUpdateLayout}
                    socket={socket}
                    bottomBar={oneDisplay.bottomBar}
                    topBar={oneDisplay.topBar}
                    fromOneDisplay={true}
                  />
                ))
              )}
            </div>

            <div className="scheduledDiv">
              <Accordion
                expanded={showSceduled}
                onChange={() => setShowScheduled((state) => !state)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  Näytön ajastukset ({oneDisplay?.scheduled.length})
                </AccordionSummary>

                <AccordionDetails>
                  <small>
                    Näyttö ei tarkasta vielä päällekkäisiä ajastuksia!
                  </small>
                  <div className="colorExpDiv">
                    <div className="tableCell-expired">Vanhentuneet</div>

                    <div className="tableCell-active">Aktiivinen</div>

                    <div className="tableCell-today">Tulossa tänään</div>

                    <div className="tableCell">Tulossa</div>
                  </div>

                  <div className="buttonsDiv">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() =>
                        dispatch(
                          actionClearScheduleArray({
                            displayId: displayId,
                            socket: socket,
                            onlyExpired: false,
                            scheduled: oneDisplay.scheduled,
                            orgID: selectedOrganization?._id,
                          })
                        )
                      }
                    >
                      Poista ajastukset ({oneDisplay?.scheduled.length})
                    </Button>{" "}
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() =>
                        dispatch(
                          actionClearScheduleArray({
                            displayId: displayId,
                            socket: socket,
                            onlyExpired: true,
                            scheduled: oneDisplay.scheduled,
                            orgID: selectedOrganization?._id,
                          })
                        )
                      }
                    >
                      Poista vanhentuneet ajastukset
                    </Button>
                  </div>

                  <div className="tableDiv">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Toiminnot</TableCell>

                          <TableCell>Nimi</TableCell>
                          <TableCell>Seuraava alkaa</TableCell>
                          <TableCell>Seuraava loppuu</TableCell>

                          <TableCell>Voimassa</TableCell>

                          <TableCell>Toistuvuus</TableCell>
                          <TableCell>Päivät</TableCell>
                          <TableCell>Viikko</TableCell>
                          <OnlyForWinfo>
                            <OnlyIfAdminMode>
                              <TableCell>Debug</TableCell>
                            </OnlyIfAdminMode>
                          </OnlyForWinfo>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {oneDisplay?.scheduled?.map((el, i) => (
                          <TableRow
                            key={i}
                            className={
                              el.expired
                                ? "tableCell-expired"
                                : el.active
                                ? "tableCell-active"
                                : el.comingToday
                                ? "tableCell-today"
                                : "tableCell"
                            }
                          >
                            <TableCell>
                              <div className="tableIconBtns">
                                <IconButton onClick={() => deleteSchedule(i)}>
                                  <DeleteIcon />
                                </IconButton>
                                <IconButton
                                  onClick={(e) => copySchedule(e, el)}
                                >
                                  <CopyIcon />
                                </IconButton>
                              </div>

                              <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                              >
                                <div className="popoverMain">
                                  <p>
                                    <strong>HUOM!</strong>{" "}
                                    <i>Tämä ajastus lisätään näytöille</i>
                                  </p>

                                  <FormControl>
                                    <FormControlLabel
                                      key={i}
                                      control={<Checkbox />}
                                      label="Yliaja kaikki ajastukset"
                                      onChange={() =>
                                        setOverwriteSchedules((state) => !state)
                                      }
                                      checked={overwriteSchedules}
                                    />
                                    <FormHelperText>
                                      Jos tämä on päällä kaikkien näyttöjen
                                      ajastukset yliajetaan ja jäljelle jää vain
                                      tämä ajastus!
                                    </FormHelperText>
                                  </FormControl>

                                  <hr />
                                  <h6>NÄYTÖT</h6>
                                  {displays?.map((el, i) => (
                                    <FormControlLabel
                                      key={i}
                                      control={<Checkbox />}
                                      label={el.name}
                                      onChange={() => handleCheckDisplay(el)}
                                      checked={selectedDisplays.some(
                                        (el2) => el2._id === el._id
                                      )}
                                    />
                                  ))}

                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => submitDisplays()}
                                  >
                                    Vahvista
                                  </Button>
                                </div>
                              </Popover>
                            </TableCell>

                            <TableCell>{el.scheduleTitle}</TableCell>
                            <TableCell>
                              ({el.Xdebug?.dailyStartSet})
                              {el.dailyStart
                                ? FormatDate(
                                    el.dailyStart,
                                    "ddd DD.MM.YYYY HH:mm"
                                  )
                                : "--:--"}
                            </TableCell>
                            <TableCell>
                              ({el.Xdebug?.dailyEndSet})
                              {el.dailyEnd
                                ? FormatDate(
                                    el.dailyEnd,
                                    "ddd DD.MM.YYYY HH:mm"
                                  )
                                : "--:--"}
                            </TableCell>

                            <TableCell>
                              {FormatDate(el.startDate, "ddd DD.MM.YYYY HH:mm")}
                              - {FormatDate(el.endDate, "ddd DD.MM.YYYY HH:mm")}
                            </TableCell>

                            <TableCell>
                              {el.repeat?.active ? "Käytössä" : "Ei käytössä"}
                            </TableCell>
                            <TableCell>{el.repeat?.days?.toString()}</TableCell>
                            <TableCell>{el.repeat?.weekType}</TableCell>
                            <OnlyForWinfo>
                              <OnlyIfAdminMode>
                                <TableCell>
                                  <textarea defaultValue={JSON.stringify(el)} />
                                </TableCell>
                              </OnlyIfAdminMode>
                            </OnlyForWinfo>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>

          <div className="settingsDiv">
            <Accordion
              expanded={oneDisplay.topBar?.use || false}
              //onChange={(e) => handleBarChange(e, "use")}
              elevation={5}
            >
              <AccordionSummary
                expandIcon={
                  <Switch
                    name="topBar"
                    color="secondary"
                    checked={oneDisplay.topBar?.use || false}
                    onChange={(e) => handleBarChange(e, "use")}
                  />
                }
              >
                Käytä yläpalkkia
              </AccordionSummary>

              <AccordionDetails>
                <div className="box1">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Koko</InputLabel>
                    <Select
                      value={oneDisplay?.topBar?.size || 60}
                      onChange={(e) => handleBarChange(e, "size")}
                      name="topBar"
                      label="Koko"
                    >
                      {barSizeOpt.map((el, i) => (
                        <MenuItem key={i} value={el.value}>
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Tyyppi
                    </InputLabel>
                    <Select
                      value={oneDisplay?.topBar?.type || "date"}
                      onChange={(e) => handleBarChange(e, "type")}
                      name="topBar"
                      label="Tyyppi"
                    >
                      {barTypeOpt.map((el, i) => (
                        <MenuItem key={i} value={el.value}>
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {oneDisplay?.topBar?.type === "text" && (
                    <FormControl fullWidth>
                      <TextField
                        value={oneDisplay?.topBar?.text || ""}
                        onChange={(e) => handleBarChange(e, "text")}
                        name="topBar"
                        placeholder="Teksti..."
                      />
                    </FormControl>
                  )}

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Fontin koko
                    </InputLabel>
                    <Select
                      value={oneDisplay?.topBar?.fontSize || 22}
                      onChange={(e) => handleBarChange(e, "fontSize")}
                      name="topBar"
                      label="Fontin koko"
                    >
                      {barFontSizeOpt.map((el, i) => (
                        <MenuItem key={i} value={el.value}>
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Taustan väri
                    </InputLabel>
                    <Select
                      value={oneDisplay?.topBar?.backgroundColor || "#fff"}
                      onChange={(e) => handleBarChange(e, "backgroundColor")}
                      name="topBar"
                      label="Taustaväri"
                    >
                      {barBackgroundColorOpt.map((el, i) => (
                        <MenuItem
                          key={i}
                          value={el.value}
                          disabled={el.disabled}
                        >
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Fontin väri
                    </InputLabel>
                    <Select
                      value={oneDisplay?.topBar?.textColor || "#fff"}
                      onChange={(e) => handleBarChange(e, "textColor")}
                      name="topBar"
                      label="Taustaväri"
                    >
                      {barBackgroundColorOpt.map((el, i) => (
                        <MenuItem
                          key={i}
                          value={el.value}
                          disabled={el.disabled}
                        >
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={oneDisplay.bottomBar?.use || false}
              // onChange={(e) => handleBarChange(e, "use")}
              elevation={5}
            >
              <AccordionSummary
                expandIcon={
                  <Switch
                    name="bottomBar"
                    color="secondary"
                    checked={oneDisplay.bottomBar?.use || false}
                    onChange={(e) => handleBarChange(e, "use")}
                  />
                }
              >
                Käytä alapalkkia
              </AccordionSummary>

              <AccordionDetails>
                <div className="box1">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Koko</InputLabel>
                    <Select
                      value={oneDisplay?.bottomBar?.size || 60}
                      onChange={(e) => handleBarChange(e, "size")}
                      name="bottomBar"
                      label="Koko"
                    >
                      {barSizeOpt.map((el, i) => (
                        <MenuItem key={i} value={el.value}>
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Tyyppi
                    </InputLabel>
                    <Select
                      value={oneDisplay?.bottomBar?.type || "date"}
                      onChange={(e) => handleBarChange(e, "type")}
                      name="bottomBar"
                      label="Tyyppi"
                    >
                      {barTypeOpt.map((el, i) => (
                        <MenuItem key={i} value={el.value}>
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {oneDisplay?.bottomBar?.type === "text" && (
                    <FormControl fullWidth>
                      <TextField
                        value={oneDisplay?.bottomBar?.text || ""}
                        onChange={(e) => handleBarChange(e, "text")}
                        name="bottomBar"
                        placeholder="Teksti..."
                      />
                    </FormControl>
                  )}

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Fontin koko
                    </InputLabel>
                    <Select
                      value={oneDisplay?.bottomBar?.fontSize || 22}
                      onChange={(e) => handleBarChange(e, "fontSize")}
                      name="bottomBar"
                      label="Fontin koko"
                    >
                      {barFontSizeOpt.map((el, i) => (
                        <MenuItem key={i} value={el.value}>
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Taustan väri
                    </InputLabel>
                    <Select
                      value={oneDisplay?.bottomBar?.backgroundColor || "#fff"}
                      onChange={(e) => handleBarChange(e, "backgroundColor")}
                      name="bottomBar"
                      label="Taustaväri"
                    >
                      {barBackgroundColorOpt.map((el, i) => (
                        <MenuItem
                          key={i}
                          value={el.value}
                          disabled={el.disabled}
                        >
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Fontin väri
                    </InputLabel>
                    <Select
                      value={oneDisplay?.bottomBar?.textColor || "#fff"}
                      onChange={(e) => handleBarChange(e, "textColor")}
                      name="bottomBar"
                      label="Taustaväri"
                    >
                      {barBackgroundColorOpt.map((el, i) => (
                        <MenuItem
                          key={i}
                          value={el.value}
                          disabled={el.disabled}
                        >
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </AccordionDetails>
            </Accordion>
            <br />

            <Button variant="contained" fullWidth onClick={submitDisplay2}>
              Tallenna
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneDisplay;
