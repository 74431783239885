import { duration } from "moment";
import { Fragment, forwardRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionSetLastDbTouch } from "../../store/appData";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { IconButton } from "@mui/material";

const MediaCard = forwardRef(({ item, styles, index }, ref) => {
  const videoRef = useRef();
  const dispatch = useDispatch();
  const { lastDbTouch } = useSelector((state) => state.appData);

  const [activeFile, setActiveFile] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeTime, setActiveTime] = useState(0);
  const [nextFile, setNextFile] = useState(null);
  const [startFadeOut, setStartFadeOut] = useState(false);

  //manual
  const [hideLeftArrow, setHideLeftArrow] = useState(false);
  const [hideRightArrow, setHideRightArrow] = useState(false);

  const [pauseAutoSwitch, setPauseAutoSwitch] = useState(false);
  useEffect(() => {
    if (item?.files) {
      // on mediaa
      const file = item.files[activeIndex];
      if (file) {
        setActiveFile(file);
      }
    }
  }, [item?.files]);

  useEffect(() => {
    if (activeFile) {
      let duration = activeFile.duration || 10;

      setActiveTime(duration);
    }

    if (item.files.length > 1) {
      const files = item.files;

      if (files[activeIndex + 1]) {
        setNextFile(files[activeIndex + 1]);
      } else {
        setNextFile(files[0]);
      }
    } else {
      const files = item.files;

      setActiveFile(files[0]);
      setActiveIndex(0);
    }
  }, [activeFile, activeIndex]);

  let interval;
  let timeout2;

  useEffect(() => {
    const max = item.files?.length - 1 || 0;
    const transitionDelay = 500;

    // backup jos interval on jäänyt nollaamatta
    if (interval) {
      clearInterval(interval);
    }

    if (max === 0) {
      // piilotetaan nuolet jos vain yksi tiedosto
      setHideLeftArrow(true);
      setHideRightArrow(true);
    }

    //auto
    if (item.switchingType !== "manual" && !pauseAutoSwitch) {
      if (activeIndex === 0) {
        setHideLeftArrow(true);
      } else if (activeIndex === max) {
        setHideRightArrow(true);
      }

      // asetetaan intervall vasta kun aika on asetettu
      if (activeTime > 0 && max > 0) {
        interval = setTimeout(() => {
          // jos actiivinen index on vielä pienempi kuin maksimi
          if (activeIndex < max) {
            const newIndex = activeIndex + 1;

            //delay jotta fade effect toimii
            //HUOM!! transitionDelay muutettava suhteessa fadeOut css kanssa

            timeout2 = setTimeout(() => {
              setActiveIndex(newIndex);
              setActiveFile(item.files[newIndex]);
              setStartFadeOut(false);

              setHideLeftArrow(false);
            }, transitionDelay);
          } else {
            //delay jotta fade effect toimii
            //HUOM!! transitionDelay muutettava suhteessa fadeOut css kanssa

            timeout2 = setTimeout(() => {
              setActiveIndex(0);
              setActiveFile(item.files[0]);
              setStartFadeOut(false);

              setHideRightArrow(false);
            }, transitionDelay);
          }

          setStartFadeOut(true);
          // aika tulee sekunteina
        }, activeTime * 1000);
      }
    } else if (max > 0) {
      // manualModessa tarkistetaan pitääko nuoli piilottaa
      if (activeIndex === max) {
        setHideRightArrow(true);
      } else {
        setHideRightArrow(false);
      }
      if (activeIndex === 0) {
        setHideLeftArrow(true);
      } else {
        setHideLeftArrow(false);
      }
    }

    return () => {
      clearTimeout(interval);
      clearTimeout(timeout2);
    };
  }, [activeTime, activeIndex, pauseAutoSwitch]);

  useEffect(() => {
    // LAST TOUCH
    // jos viimeisestä painalluksesta mennyt yli annetun ajan -> palaa auto tilaan
    const waitTimeAfterTouch = item.timeOutToAuto || 10;

    if (lastDbTouch >= waitTimeAfterTouch) {
      dispatch(actionSetLastDbTouch(-1));
      setPauseAutoSwitch(false);
      setHideLeftArrow(false);
      setHideRightArrow(false);
    }
  }, [lastDbTouch]);

  const handleLoadedMetadata = () => {
    // katsoo videon pituuden
    const video = videoRef.current;
    const fix = 0.4;

    if (!video) return;

    setActiveTime(video.duration - fix);
    //console.log(`The video is ${video.duration} seconds long.`);
  };

  const handleManualSlide = (direction) => {
    const max = item.files?.length - 1 || 0;
    dispatch(actionSetLastDbTouch(0));
    setPauseAutoSwitch(true);

    if (direction === "right") {
      if (activeIndex < max) {
        const newIndex = activeIndex + 1;
        setActiveIndex(newIndex);
        setActiveFile(item.files[newIndex]);
      }
    } else if (direction === "left") {
      if (activeIndex > 0) {
        const newIndex = activeIndex - 1;

        setActiveIndex(newIndex);
        setActiveFile(item.files[newIndex]);
      }
    }
  };
/*
  console.table(
    "MEDIA",

    activeTime,
    activeIndex,

    pauseAutoSwitch,
    startFadeOut,
    item.switchingType,
    item.timeOutToAuto
  );*/
  //console.log(item, activeFile);

  if (!item?.files?.length) {
    return (
      <div className="MediaCard" ref={ref}>
        <p>Ei mediaa valuttuna...</p>
      </div>
    );
  }
  if (activeTime === 0) {
    return (
      <div className="MediaCard" ref={ref}>
        <p>LATAA...</p>
      </div>
    );
  }

  return (
    <div className="MediaCard" ref={ref}>
      {item.switchingType === "manual" ||
      item.switchingType === "autoManual" ? (
        <Fragment>
          {!hideRightArrow && (
            <span
              className="right-direction"
              onClick={() => handleManualSlide("right")}
            >
              <IconButton className="iconBtn">
                <ChevronRightIcon />
              </IconButton>
            </span>
          )}

          {!hideLeftArrow && (
            <span
              className="left-direction"
              onClick={() => handleManualSlide("left")}
            >
              <IconButton className="iconBtn">
                <ChevronLeftIcon />
              </IconButton>
            </span>
          )}
        </Fragment>
      ) : null}

      {activeIndex >= 0 ? (
        activeFile?.format === "mp4" ? (
          <div
            key={item._id}
            className={startFadeOut ? "videoDiv fadeOut" : "videoDiv fadeIn"}
          >
            <video
              src={activeFile.url}
              autoPlay
              loop
              muted
              ref={videoRef}
              onLoadedMetadata={handleLoadedMetadata}
              poster="https://iwip-displays.s3.eu-north-1.amazonaws.com/transparent.png"
            />
          </div>
        ) : activeFile?.format === "png" ||
          activeFile?.format === "jpg" ||
          activeFile?.format === "jpeg" ||
          activeFile?.format === "gif" ? (
          <Fragment>
            <div
              key={item._id}
              className={startFadeOut ? "imgDiv fadeOut" : "imgDiv fadeIn"}
              style={{
                backgroundImage: `url("${activeFile?.url}")`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            />
          </Fragment>
        ) : (
          <p>Tuntematon tiedosto</p>
        )
      ) : (
        <p>Ei mediaa</p>
      )}
    </div>
  );
});

export default MediaCard;
