import React from "react";
import DiaCard from "./DiaCard";
import NewsCard from "./NewsCard";
import WeatherCard from "./WeatherCard";
import VideoCard from "./VideoCard";
import ClockCard from "./ClockCard";
import MediaCard from "./MediaCard";

const DBCard = React.forwardRef(function DBCard(
  { type, data, setCardIndex, setOpenCards, i, styles },
  ref
) {

  if (!data) {
    return null;
  }

  switch (data.type) {
    case "dia":
      return <DiaCard data={data} styles={styles} ref={ref} />;
    case "weather":
      return <WeatherCard item={data} styles={styles} />;

    case "news":
      return (
        <NewsCard
          feedUrl={data.rssFeed}
          options={data.options}
          styles={styles}
          intervalSpeed={data.interval}
        />
      );

    case "video":
      return <VideoCard item={data} styles={styles} ref={ref} />;

    case "clock":
      return <ClockCard styles={styles} ref={ref} />;
    case "media":
      return <MediaCard item={data} styles={styles} ref={ref} />;

    case "empty":
      return (
        <div className="emptyCard">
          <p
            onClick={() => {
              setCardIndex(i);
              setOpenCards(true);
            }}
          >
            Valitse
          </p>
        </div>
      );
    default:
      return (
        <div>
          <p>Ei Tehty</p>
        </div>
      );
  }
});
export default DBCard;
