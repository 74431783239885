import moment from "moment";
import "moment/locale/fi"; // without this line it didn't work

export const FormatDate = (date, format) => {
  return moment(date).format(format);
};

export function AddToDate(date, number, unit, format) {
  if (format) {
    return moment(date).add(number, unit).format(format);
  }

  return moment(date).add(number, unit);
}

export function GetDurationToTime(startDate, targetDate) {
  let duration;
  const now = moment(new Date()); //todays date

  if (startDate) {
    const start = moment(startDate);

    duration = moment.duration(start.diff(targetDate));
  } else {
    duration = moment.duration(now.diff(targetDate));
  }
  const units = duration.asSeconds();

  return units * -1;
}

function convertTimeToInt(time) {
  const h = time.hours();
  const min = time.minutes();

  return h * 100 + min;
}

export function setActiveLayout(DB, setActiveLayout, showBlackScreen) {
  const scheduled = DB?.scheduled;
  let cards = [];
  let type = null;
  let timeOutToAuto;
  let switchingType;

  // ALUSTUKSET
  const today = moment();
  const todayWeek = today.format("w");
  const todayDay = today.format("dd");
  const isWeekEven = parseInt(todayWeek) % 2 === 0;

  let tempScheduled = scheduled;
  let activeSchedule;

  if (showBlackScreen) {
    setActiveLayout(null);
    return null;
  }

  if (!tempScheduled) {
    // näytöllä ei ole välttämättä ajastuksia
    // ei tarvetta käydä muuta läpi
    cards = DB.cards2;
    type = DB.type;
    timeOutToAuto = DB.timeOutToAuto;
    switchingType = DB.switchingType;

    const data = { cards, type, timeOutToAuto, switchingType };
    //  console.log("SET BASIC 1", data);

    setActiveLayout(data);

    return null;
  }
  tempScheduled = tempScheduled.map((el) => {
    const start = moment(el.startDate);
    const end = moment(el.endDate);
    const repeat = el.repeat;

    const dailyStartTimeInt = convertTimeToInt(start);
    const dailyEndTimeInt = convertTimeToInt(end);
    const todayTimeInt = convertTimeToInt(today);
    const dailyStartTime = start.format("HH:mm");
    const dailyEndTime = end.format("HH:mm");
    let content = { ...el };

    const todayIsBetweenDates = today.isBetween(start, end);
    const startDateIsInFuture = start.isAfter(today);
    const endsInTomorrow = dailyEndTimeInt < dailyStartTimeInt;
    const isEndTimeInPast = todayTimeInt > dailyEndTimeInt;
    const isStartTimeInPast = todayTimeInt > dailyStartTimeInt;

    let weekIsValid = false;
    let daysIsValid = false;

    let dailyEnd;
    let dailyStart;

    // ajastus ei voi olla käytössä koska tämä päivä ei ole start ja end välissä
    //ja aloitu aika ei ole tulevaisuudessa
    if (!todayIsBetweenDates && !startDateIsInFuture) {
      content.expired = true;
      // ei tarvetta tehdä muita tarkastuksia
      return { debugMsg: "First check", ...content };
    }

    // ei voi olla voimassa, koska vasta alkaa tulevaisuudessa
    if (startDateIsInFuture) {
      content.isInfuture = true;
      return { debugMsg: "startDateIsInFuture", ...content };
      //      return {debugMsg: ""};
    }

    // TARKISTUKSIA

    // tarkistuksia

    // VIIKKO
    if (el.repeat.weekType === "all") {
      weekIsValid = true;
    } else if (el.repeat.weekType === "even") {
      if (isWeekEven) {
        weekIsValid = true;
      }
    } else if (el.repeat.weekType === "odd") {
      if (!isWeekEven) {
        weekIsValid = true;
      }
    }

    content.xweekIsValid = weekIsValid;

    // PÄIVÄ
    daysIsValid = repeat.days.includes(todayDay);
    console.log("XX", repeat.days, todayDay);

    content.xdaysIsValid = daysIsValid;

    if (!weekIsValid) {
      return { debugMsg: "weekNotValid" };
    } else if (!daysIsValid) {
      return { debugMsg: "dayNotValid" };
    }

    // MUOKKAUS
    // lopetusaika mennyt tältäpäivältä
    if (isEndTimeInPast) {
      content.editMsg = "isEndTimeInPast";

      if (endsInTomorrow) {
        content.editMsg2 = "endsInTomorrow";

        dailyStart = `${moment(today).format("YYYY-MM-DD")}T${dailyStartTime}`;
      } else {
        content.editMsg2 = "not endsInTomorrow";

        dailyStart = `${moment(today)
          .add(1, "day")
          .format("YYYY-MM-DD")}T${dailyStartTime}`;
      }

      dailyEnd = `${moment(today)
        .add(1, "day")
        .format("YYYY-MM-DD")}T${dailyEndTime}`;
    } else {
      content.dubug = 9;
      // on käytössä
      dailyStart = `${moment(today).format("YYYY-MM-DD")}T${dailyStartTime}`;

      dailyEnd = `${moment(today).format("YYYY-MM-DD")}T${dailyEndTime}`;

      // jos loppumisaika on eripäivällä kuin alkamisaika (esim. yön yli)
      // ja loppumisaika ei ole vielä menneisyydessä kuluvalta päivältä
      // niin pitää huomioida, että alkamispäivä on eilisellä, jos ajat eri pvm
      if (endsInTomorrow) {
        content.editMSg = "endsInTomorrow";
        //lisätään loppumis päivään yksi, joten huomioidaan vrk vaihto
        dailyStart = dailyEnd = `${moment(today)
          .subtract(1, "day")
          .format("YYYY-MM-DD")}T${dailyEndTime}`;

        dailyEnd = `${moment(today).format("YYYY-MM-DD")}T${dailyEndTime}`;
      } else {
        content.editMSg = "not endsInTomorrow";
        dailyStart = `${moment(today).format("YYYY-MM-DD")}T${dailyStartTime}`;

        dailyEnd = `${moment(today).format("YYYY-MM-DD")}T${dailyEndTime}`;
      }
    }
    ///

    content.dailyStart = dailyStart;
    content.dailyEnd = dailyEnd;

    const todayIsBetweenDailyTimes = today.isBetween(dailyStart, dailyEnd);

    if (todayIsBetweenDailyTimes) {
      content.active = true;
    }

    return content;
  });

  activeSchedule = tempScheduled.find((el) => el?.active);

  console.log("DATES", tempScheduled);

  if (!activeSchedule) {
    cards = DB.cards2;
    type = DB.type;
    timeOutToAuto = DB.timeOutToAuto;
    switchingType = DB.switchingType;

    const data = { cards, type, timeOutToAuto, switchingType };
    // console.log("SET BASIC", data);

    setActiveLayout(data);
  } else {
    cards = activeSchedule.cards2;
    type = activeSchedule.type;
    timeOutToAuto = activeSchedule.timeOutToAuto;
    switchingType = activeSchedule.switchingType;
    const data = { cards, type, timeOutToAuto, switchingType };
    //console.log("SET ACTIVE", data);

    setActiveLayout(data);
  }

  // setActiveCards(cards);
  // setActiveType(type);

  return activeSchedule;
}
