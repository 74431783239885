import React from "react";
import useQueryParam from "../../utils/UseQueryParams";

import "../styles/DB.scss";

import DBWithLogin from "./DBWithLogin";
import DBUrl from "./DBUrl";
import DBUrl2 from "./DBUrl2";
//useAuth

const DB = ({ usePreview, preDB, closePreview }) => {
  return (
    <DBUrl2 usePreview={usePreview} preDB={preDB} closePreview={closePreview} />
  );
};

export default DB;
