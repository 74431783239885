import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Collapse,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { actionDBLogout } from "../../store/DB";
import { useDispatch, useSelector } from "react-redux";

import getLocalIp from "../../utils/getLocalIp";
import { FormatDate } from "../../utils/dates";

import {
  JsonView,
  allExpanded,
  darkStyles,
  defaultStyles,
  collapseAllNested,
} from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const DBPinCode = ({
  askPin,
  DBLoginData,
  resetCornerClicks,
  enterPressed,
  setEnterPressed,
  doSpeedTest,
  setDoSpeedTest,
  DB,
}) => {
  const dispatch = useDispatch();
  const idName = process.env.REACT_APP_DB_ID_NAME_LOCALSTORAGE;

  const { networkStatus } = useSelector((state) => state.appData);

  const [enteredPin, setEnteredPin] = useState("");
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    return () => {
      setEnterPressed(false);
    };
  }, []);

  useEffect(() => {
    confirmPinCode();
  }, [enterPressed]);

  const confirmPinCode = () => {
    console.log("enter", enteredPin);
    if (process.env.REACT_APP_EXIT_PIN === enteredPin) {
      handleLogout();
    } else if (enteredPin === "0000") {
      console.log("enter", enteredPin);

      setShowInfo(true);
    }
  };

  const handleLogout = () => {
    dispatch(actionDBLogout(DBLoginData?.token));
  };

  const reset = () => {
    setEnteredPin("");
    setShowInfo(false);
    resetCornerClicks();
  };

  if (!askPin) return null;
  const versionNumber = process.env.REACT_APP_VERSION;

  return (
    <div className="AskPinRoot">
      <div className="containerDiv">
        <h3>VERSION:{versionNumber} </h3>
        <div className="pinCodeDiv">
          <TextField
            className="text"
            value={enteredPin}
            placeholder="pin..."
            onChange={(e) => {
              setEnteredPin(e.target.value);
            }}
            autoFocus
            fullWidth
          />

          <div className="buttons">
            <Button
              variant="outlined"
              color="primary"
              onClick={confirmPinCode}
              fullWidth
            >
              Vahvista
            </Button>

            <Button
              variant="outlined"
              color="secondary"
              onClick={() => reset()}
            >
              Peruuta
            </Button>
          </div>
        </div>

        {showInfo && (
          <div className="contentDiv">
            <p>INFO</p>

            <div>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <p>Perustiedot</p>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="acDiv1">
                    <div className="networkDiv">
                      <p>
                        VERKKO:{" "}
                        {networkStatus ? (
                          <span className="online">ONLINE</span>
                        ) : (
                          <span className="offline">OFFLINE</span>
                        )}
                      </p>
                      <p>
                        <strong>Origin:</strong> {window.location.origin}
                      </p>
                      <p>
                        <strong>Href:</strong> {window.location.href}
                      </p>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <p>Toiminnot</p>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="acDiv2">
                    <div className="divButtons">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setDoSpeedTest((state) => !state)}
                      >
                        Nopeustesti
                      </Button>

                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => window.location.reload()}
                      >
                        Päivitä sivu
                      </Button>
                    </div>

                    <div className="iframe">
                      {doSpeedTest && <iframe src="https://fast.com" />}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                  <p>Tekniset tiedot</p>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="acDiv3">
                    <h2>AIKALEIMAT</h2>
                    <div className="allTimesDiv">
                      <div>
                        <p>DATA HAETTU</p>
                        <ul>
                          {DB?.timestamps?.fetched?.map((el, i) => (
                            <li key={i}>{`${i + 1} ${FormatDate(
                              el,
                              "ddd DD.MM.YYYY HH:mm:ss"
                            )}`}</li>
                          ))}
                        </ul>
                      </div>
                      <div>
                        <p>Kyselyt</p>
                        <ul>
                          {DB?.timestamps?.askedUpdate?.map((el, i) => (
                            <li key={i}>{`${i + 1} ${el.status} / ${FormatDate(
                              el.date,
                              "ddd DD.MM.YYYY HH:mm:ss"
                            )}`}</li>
                          ))}
                        </ul>
                      </div>

                      <div>
                        <p>ASETTELU / Asettelu päivitetty</p>
                        <ul>
                          {DB?.timestamps?.layoutInfo?.map((el, i) => (
                            <li key={i}>{`${i + 1} ${el.name} / ${FormatDate(
                              el.updatedAt,
                              "ddd DD.MM.YYYY HH:mm:ss"
                            )}`}</li>
                          ))}
                        </ul>
                      </div>
                      <div>
                        <p>LINKITETTY</p>
                        <ul>
                          {DB?.timestamps?.linked?.map((el, i) => (
                            <li key={i}>{`${i + 1} ${FormatDate(
                              el,
                              "ddd DD.MM.YYYY HH:mm:ss"
                            )}`}</li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    <hr />

                    <div className="jsonDiv">
                      <h2>NÄYTÖN DATA</h2>
                      <JsonView
                        data={DB}
                        //  shouldExpandNode={allExpanded}
                        style={defaultStyles}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <p>LOCALSTORAGE</p>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="acDiv4">
                    <div className="itemDiv">
                      <h4>No need to fetch</h4>
                      <div className="jsonDiv">
                        <JsonView
                          data={
                            JSON.parse(localStorage.getItem("noNeedToFetch")) ||
                            []
                          }
                          shouldExpandNode={(level) => level < 2}
                          style={defaultStyles}
                        />
                      </div>
                    </div>

                    <hr />
                    <div className="itemDiv">
                      <h4>Need to fetch</h4>
                      <div className="jsonDiv">
                        <JsonView
                          data={
                            JSON.parse(localStorage.getItem("needToFetch")) ||
                            []
                          }
                          shouldExpandNode={(level) => level < 2}
                          style={defaultStyles}
                        />
                      </div>
                    </div>

                    <hr />

                    <div className="itemDiv">
                      <h4>Asked to refresh</h4>
                      <div className="jsonDiv">
                        <JsonView
                          data={
                            JSON.parse(localStorage.getItem("askedRefresh")) ||
                            []
                          }
                          shouldExpandNode={(level) => level < 2}
                          style={defaultStyles}
                        />
                      </div>
                    </div>

                    <div className="itemDiv">
                      <h4>Clear localstorage</h4>
                      <div className="jsonDiv">
                        <JsonView
                          data={
                            JSON.parse(localStorage.getItem("clearStorage")) ||
                            []
                          }
                          shouldExpandNode={(level) => level < 2}
                          style={defaultStyles}
                        />
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <p>AJASTUKSET</p>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="acDiv2">
                    <p>KELLO UTC aikaa</p>
                    <JsonView
                      data={DB?.scheduled || []}
                      shouldExpandNode={(level) => level < 2}
                      style={defaultStyles}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DBPinCode;
