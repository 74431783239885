export const orientationOpt = [
  { label: "Vaaka", value: "0" },
  { label: "Pysty", value: "90" },
];

export const displayColOpt = [
  { label: "1", value: "12" },
  { label: "2", value: "6" },
];

export const displayRowOpt = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
];

export const cardPcsOpt = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
];

export const cardTypesOpt = [
  { label: "Ei valintaa", value: "-" },
  { label: "Dia", value: "dia" },
  // { label: "Video", value: "video" },
  { label: "Media", value: "media" },

  // { label: "Uutiset", value: "news", disabled: true },
  // { label: "Sää", value: "weather", disabled: true },
  // { label: "Kello", value: "clock", disabled: true },
];

export const displayLayout1Opt = [
  { label: "Koko", value: "full", disabled: true },
];
export const displayLayout2Opt = [
  { label: "Vierekkäin", value: "side-by-side", disabled: false },
  { label: "Allekkain", value: "up-down", disabled: false },
];
export const displayLayout3Opt = [
  { label: "Vierekkäin", value: "side-by-side", disabled: false },
  { label: "Allekkain", value: "up-down", disabled: false },
  { label: "2x1", value: "2-1", disabled: false },
  { label: "1x2", value: "1-2", disabled: false },
];
export const displayLayout4Opt = [
  { label: "Vierekkäin", value: "side-by-side", disabled: false },
  { label: "Allekkain", value: "up-down", disabled: false },
  { label: "2x2", value: "2-2", disabled: false },
];

export const cardSizesOpt = [
  { label: "Koko", value: 12 },
  { label: "puoli", value: 6 },
  { label: "1/3", value: 4 },
  { label: "1/4", value: 3 },
  { label: "true", value: true },
];

export const yleRssOpt = [
  {
    label: "Urheilu",
    value:
      "https://feeds.yle.fi/uutiset/v1/recent.rss?publisherIds=YLE_URHEILU",
  },
  {
    label: "Pääuutiset",
    value: "https://feeds.yle.fi/uutiset/v1/majorHeadlines/YLE_UUTISET.rss",
  },
];

export const textPositionOpt = [
  {
    label: "Ylä vasen",
    value: `{"position": "absolute", "top": "5px", "left": "5px"}`,
  },
  {
    label: "Ylä oikea",
    value: `{"position": "absolute", "top": "5px", "right": "5px"}`,
  },
  {
    label: "Ala vasen",
    value: `{"position": "absolute", "bottom": "5px", "left": "5px"}`,
  },
  {
    label: "Ala oikea",
    value: `{"position": "absolute", "bottom": "5px", "right": "5px"}`,
  },
  {
    label: "Ylä keskellä",
    value: `{"position": "absolute", "top": "5px", "right": "0", "left": "0", "textAlign": "center"}`,
  },
  {
    label: "Ala keskellä",
    value: `{"position": "absolute", "bottom": "5px", "right": "0", "left": "0", "textAlign": "center"}`,
  },
];

export const cardMaxHeightOpt = [
  {
    label: "-",
    value: "",
  },
  {
    label: "1/3",
    value: `{"maxHeight": "33vh"}`,
  },
  {
    label: "1/4",
    value: `{"maxHeight": "25vh"}`,
  },
];

export const cardMinHeightOpt = [
  {
    label: "-",
    value: ``,
  },
  {
    label: "1/3",
    value: `{"minHeight": "33vh"}`,
  },
  {
    label: "1/2",
    value: `{"minHeight": "50vh"}`,
  },
  {
    label: "3/4",
    value: `{"minHeight": "75vh"}`,
  },
];

export const bcImageOpt = [
  { label: "Venytä", value: "cover", disabled: false },
  { label: "Näytä kokonaan", value: "contain", disabled: false },
];

export const layoutBackgroundOpt = [
  { label: "Musta", value: "black" },
  { label: "Musta 2", value: "#37323E" },
  { label: "Harmaa", value: "#d4d6d9" },
  { label: "Tumma harmaa", value: "#424543" },
  { label: "Vihreä", value: "#586F6B" },
  { label: "sininen", value: "#2E5A61" },
];

export const layoutBackgroundImageOpt = [
  {
    label: "Kuva 1",
    value:
      "https://res.cloudinary.com/delccokng/image/upload/v1675950782/files/StockSnap_VCJK4NBK4W_zzq3nk.jpg",
  },
  {
    label: "Kuva 2",
    value:
      "https://res.cloudinary.com/delccokng/image/upload/v1675950837/files/StockSnap_XUFJXBNLCL_boxabd.jpg",
  },
  {
    label: "Kuva 3",
    value:
      "https://res.cloudinary.com/delccokng/image/upload/v1675950924/files/StockSnap_PM02507VVE_sgznrp.jpg",
  },
  {
    label: "Kuva 4",
    value:
      "https://res.cloudinary.com/delccokng/image/upload/v1675950924/files/StockSnap_CXNXR1QOFU_ps8abl.jpg",
  },
];

export const userRoleOpt = [
  { label: "Winfo", value: "winfo", disabled: true },
  { label: "User", value: "user", disabled: false },
];

export const displayOrientationOpt = [
  { label: "0", value: 0 },
  { label: "180", value: 180 },
  { label: "90", value: 90 },
  { label: "-90", value: -90 },
];

export const barSizeOpt = [
  { label: "Pieni", value: 60 },
  { label: "Keski", value: 100 },
  { label: "Suuri", value: 150 },
];

export const barFontSizeOpt = [
  { label: "Pieni", value: 22 },
  { label: "Keski", value: 48 },
  { label: "Suuri", value: 84 },
];

export const timerDurationOpt = [
  { label: "10sek", value: 10 },
  { label: "30sek", value: 30 },
  { label: "1min", value: 60 },
  { label: "5min", value: 300 },
  { label: "10min", value: 600 },
  { label: "1h", value: 3600 },
];

export const barTypeOpt = [
  { label: "Teksti", value: "text" },
  { label: "Kello", value: "clock" },
  { label: "PVM", value: "date" },
  { label: "Kello + PVM", value: "clockDate" },
];

export const barBackgroundColorOpt = [
  { label: "Valkoinen", value: "#fff" },
  { label: "Musta", value: "#000" },
  { label: "Kulta", value: "#d4af37" },

  { label: "MUU", value: "", disabled: true },
];

export const diaSwitchingOpt = [
  { label: "Auto", value: "auto" },
  { label: "manual", value: "manual" },
  { label: "auto + manual", value: "autoManual" },
];

export const scheduleWeekOpt = [
  { label: "Kaikki", value: "all" },
  { label: "Parittomat", value: "odd" },
  { label: "Parilliset", value: "even" },
];

export const weekdaysOpt = [
  { label: "Ma", value: "ma" },
  { label: "Ti", value: "ti" },
  { label: "Ke", value: "ke" },
  { label: "To", value: "to" },
  { label: "Pe", value: "pe" },
  { label: "la", value: "la" },
  { label: "Su", value: "su" },
  /*{ label: "Kaikki", value: "all" },
  { label: "Arkipäivät", value: "workDays" },
  { label: "Viikonloppu", value: "weekend" },*/
];

export const displayRoleOpt = [
  { label: "Tunnukset", value: "basic" },
  { label: "Url", value: "public" },
];
