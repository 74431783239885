import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { actionOpenAlert } from "./alert";
import { actionCloseModal } from "./modal";
import proxy from "../utils/proxy";

// Slice
const slice = createSlice({
  name: "display",
  initialState: {
    displays: null,
    loadingDisplays: true,
    oneDisplay: undefined,
  },
  reducers: {
    getDisplays: (state, action) => {
      state.displays = action.payload;
      state.loadingDisplays = false;
    },
    getDisplayById: (state, action) => {
      state.oneDisplay = action.payload;
    },
    newDisplay: (state, action) => {
      state.displays = [action.payload, ...state.displays];
    },
    deleteDisplay: (state, action) => {
      state.displays = state.displays?.filter(
        (el) => el._id !== action.payload
      );
    },
    setOneDisplay: (state, action) => {
      state.oneDisplay = action.payload;
    },
    updateDisplay: (state, action) => {
      state.oneDisplay = {
        ...state.oneDisplay,
        ...action.payload,
      };
    },
    clearDisplay: (state, action) => {
      state.oneDisplay = null;
    },
    setDisplays: (state, action) => {
      state.displays = action.payload.displays;
      state.loadingDisplays = action.payload.loading;
    },
  },
});
export default slice.reducer;

// Actions
const {
  getDisplays,
  newDisplay,
  deleteDisplay,
  setOneDisplay,
  getDisplayById,
  updateDisplay,
  clearDisplay,
  setDisplays,
} = slice.actions;

export const actionGetDisplays = (params) => async (dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const url = `${proxy}/api/displays?orgID=${params?.orgID || ""}`;
    const response = await axios.get(url, config);
    const data = response.data;

    dispatch(getDisplays(data));
  } catch (err) {
    console.log("VIRHE Ladattaessa näyttöjä:", err.message);
  }
};

export const actionGetDisplayById = (params) => async (dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.get(
      `${proxy}/api/displays/get-by-id/${params.id}?orgID=${
        params?.orgID || ""
      }`,
      config
    );
    const data = response.data;

    dispatch(getDisplayById(data));
  } catch (err) {
    console.log("VIRHE Ladattaessa organisaation näyttöä:", err.message);
  }
};

export const actionSetOneDisplay = (payload) => async (dispatch) => {
  dispatch(setOneDisplay(payload));
};

export const actionNewDisplay = ({
  name,
  desc,
  username,
  password,
  organizationId,
  role,
}) => async (dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const body = JSON.stringify({
      name,
      desc,
      username,
      password,
      organizationId,
      role,
    });

    const response = await axios.post(
      `${proxy}/api/displays/add`,
      body,
      config
    );
    const data = response.data;

    dispatch(newDisplay(data));

    dispatch(
      actionOpenAlert({
        open: true,
        severity: "success",
        text: `"${data.name}" lisätty`,
        timeout: 2000,
        autoHide: true,
      })
    );
  } catch (err) {
    dispatch(
      actionOpenAlert({
        open: true,
        severity: "error",
        text: `Epäonnistui: ${err.message}`,
        timeout: 5000,
        autoHide: true,
      })
    );
    console.error("VIRHE Luodessa näyttöä:", err.message);
  }
};

export const actionDeleteDisplay = (id) => async (dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.delete(
      `${proxy}/api/displays/delete/${id}`,
      config
    );

    const data = response.data;

    if (data && data.name) {
      dispatch(deleteDisplay(id));

      dispatch(
        actionOpenAlert({
          open: true,
          severity: "success",
          text: `"${data.name}" poistettu`,
          timeout: 5000,
          autoHide: true,
        })
      );

      dispatch(actionCloseModal());
    } else {
      console.error("Epäonnistui");
    }
  } catch (err) {
    console.error("Virhe poistettaessa näyttöä", err.message);
  }
};

export const actionUpdateDisplay = ({
  displayId,
  name,
  orientation,
  cardsPcs,
  layout,
  socket,
  selectedCards,
  bottomBar,
  topBar,
  reloadDB,
  orgID,
}) => async (dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const body = JSON.stringify({
      name,
      orientation,
      cardsPcs,
      layout,
      selectedCards,
      bottomBar,
      topBar,
      orgID,
    });

    const response = await axios.put(
      `${proxy}/api/displays/${displayId}/update`,
      body,
      config
    );
    const data = response.data;

    if (data.displays) {
      const socketData = {
        to: data.displays.map((el) => el._id),
      };

      socket?.emit("DB_SOCKET", socketData);
    } else if (reloadDB) {
      const socketData = {
        to: [displayId],
        type: "SILENT_FETCH",
      };

      socket?.emit("DB_ACTION", socketData);
    }

    dispatch(
      actionOpenAlert({
        open: true,
        severity: "success",
        text: `"${data.name}" muokattu`,
        timeout: 5000,
        autoHide: true,
      })
    );

    dispatch(actionCloseModal());
  } catch (err) {
    dispatch(
      actionOpenAlert({
        open: true,
        severity: "error",
        text: `"Epäonnistui`,
        timeout: 5000,
        autoHide: true,
      })
    );
    console.log("VIRHE muokatteassa näyttöä:", err.message);
  }
};

export const actionUpdateDisplayRedux = (data) => async (dispatch) => {
  try {
    if (!data) {
      return dispatch(clearDisplay());
    }
    dispatch(updateDisplay(data));
  } catch (err) {
    console.error("Virhe redux näyttöä", err.message);
  }
};

export const actionSetScheduleToDisplay = ({
  displayId,
  layoutId,
  orgID,
  socket,
  scheduleTitle,

  //
  startDate,
  endDate,
  //
  active,
  days,
  weekType,
}) => async (dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const body = JSON.stringify({
      layoutId,
      orgID,
      scheduleTitle,
      //
      startDate,
      endDate,
      //
      active,
      days,
      weekType,
    });

    const response = await axios.put(
      `${proxy}/api/displays/${displayId}/set-schedule`,
      body,
      config
    );

    const data = response.data;

    const socketData = {
      to: [displayId],
      type: "SILENT_FETCH",
    };

    socket?.emit("DB_ACTION", socketData);

    dispatch(updateDisplay(data));

    dispatch(
      actionOpenAlert({
        open: true,
        severity: "success",
        text: `"${data.name}" Ajastus lisätty`,
        timeout: 2000,
        autoHide: true,
      })
    );

    dispatch(actionCloseModal());
  } catch (err) {
    console.log("VIRHE Ladattaessa näyttöjä:", err.message);
  }
};

export const actionClearScheduleArray = ({
  displayId,
  socket,
  onlyExpired,
  scheduled,
  orgID,
}) => async (dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const body = JSON.stringify({
      onlyExpired,
      scheduled,
      orgID,
    });
    const response = await axios.put(
      `${proxy}/api/displays/${displayId}/clear-schedule-array`,
      body,
      config
    );

    const data = response.data;

    const socketData = {
      to: [data._id],
      type: "RELOAD_PAGE",
    };

    socket?.emit("DB_ACTION", socketData);

    dispatch(updateDisplay(data));

    dispatch(
      actionOpenAlert({
        open: true,
        severity: "success",
        text: `"${data.name}" ajastukset tyhjennetty`,
        timeout: 5000,
        autoHide: true,
      })
    );

    dispatch(actionCloseModal());
  } catch (err) {
    console.error("VIRHE poistettaessa ajastuksia:", err.message);
    dispatch(
      actionOpenAlert({
        open: true,
        severity: "error",
        text: `"Epäonnistui`,
        timeout: 5000,
        autoHide: true,
      })
    );
  }
};

export const actionUpdateScheduledArray = ({
  displayId,
  socket,
  action,
  scheduledArray,
  orgID,
}) => async (dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const body = JSON.stringify({
      orgID,
      action,
      scheduledArray,
    });
    const response = await axios.put(
      `${proxy}/api/displays/${displayId}/update-schedule-array`,
      body,
      config
    );

    const data = response.data;

    const socketData = {
      to: [data._id],
      type: "RELOAD_PAGE",
    };

    socket?.emit("DB_ACTION", socketData);

    dispatch(updateDisplay(data));

    dispatch(
      actionOpenAlert({
        open: true,
        severity: "success",
        text: `"${data.name}" ajastukset tyhjennetty`,
        timeout: 5000,
        autoHide: true,
      })
    );

    dispatch(actionCloseModal());
  } catch (err) {
    console.error("VIRHE poistettaessa ajastuksia:", err.message);
    dispatch(
      actionOpenAlert({
        open: true,
        severity: "error",
        text: `"Epäonnistui`,
        timeout: 5000,
        autoHide: true,
      })
    );
  }
};

export const actionSetDisplays = (displays, loading) => async (dispatch) => {
  dispatch(setDisplays({ displays, loading }));
};

export const actionClearLocalStorage = (displayId) => async (dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const body = JSON.stringify({
      displayId,
    });
    const response = await axios.put(
      `${proxy}/api/displays/clear-local-storage`,
      body,
      config
    );

    const data = response.data;

    dispatch(
      actionOpenAlert({
        open: true,
        severity: "success",
        text: `muisti tyhjennetty`,
        timeout: 5000,
        autoHide: true,
      })
    );

    dispatch(actionCloseModal());
  } catch (err) {
    console.error("VIRHE poistettaessa ajastuksia:", err.message);
  }
};

export const actionSetScheduleToDisplays = ({
  displays,
  schedule,
  orgID,
  overwriteSchedules,
  displayId,
}) => async (dispatch) => {
  const config = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const body = JSON.stringify({
      displays,
      schedule,
      orgID,
      overwriteSchedules,
      displayId,
    });

    const response = await axios.put(
      `${proxy}/api/displays/set-schedule-to-displays`,
      body,
      config
    );

    const data = response.data;

    console.log("DATA", data);

    dispatch(
      actionOpenAlert({
        open: true,
        severity: "success",
        text: `"${data.name}" Ajastus kopioitu (${data.count}) näytölle`,
        timeout: 4000,
        autoHide: true,
      })
    );
  } catch (err) {
    console.log("VIRHE Ladattaessa näyttöjä:", err.message);
  }
};
