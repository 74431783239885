import { Fragment, useEffect, useRef, useState } from "react";
import "../styles/DB2.scss";
import { actionToggleAppbar } from "../../store/appData";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import {
  actionCheckNeedToFetch,
  actionGetDBWithLink,
  actionSetActiveLayout,
  actionSetDisplayData,
  actionUpdateDBInfo,
} from "../../store/DB";
import { FormatDate, setActiveLayout } from "../../utils/dates";
import DBPinCode from "./DBPinCode";
import QRCode from "react-qr-code";
import { v4 as uuidv4 } from "uuid";
import DBContent2 from "./DBContent2";

const padding = 0;

const DBUrl2 = ({ usePreview, preDB, closePreview }) => {
  const idName = process.env.REACT_APP_DB_ID_NAME_LOCALSTORAGE;
  const dispatch = useDispatch();

  // DELAYS
  const pollingDelay = 1000 * 60 * 3;
  const checkLayoutDelay = 1000 * 60 * 1;

  const hostname = window.location.host;
  const protocol = window.location.protocol;

  const screenRef = useRef(null);
  const rootRef = useRef(null);

  const padding = 0;
  const rowGap = 25;
  const colGap = 25;

  const { DB, loadingDB, activeLayout } = useSelector((state) => state.DB);
  const { dateAndTime, networkStatus } = useSelector((state) => state.appData);

  const [screenSize, setScreenSize] = useState(null);

  const [deviceId, setDeviceId] = useState(localStorage.getItem(idName));

  const [topBar, setTopBar] = useState(null);
  const [bottomBar, setBottomBar] = useState(null);
  const [askPin, setAskPin] = useState(false);
  const [enterPressed, setEnterPressed] = useState(false);

  const [leftClickedCount, setLeftClickedCount] = useState(0);
  const [rightClickedCount, setRightClickedCount] = useState(0);
  const [rightTopClickedCount, setRightTopClickedCount] = useState(0);

  const [doSpeedTest, setDoSpeedTest] = useState(false);
  const [states, setStates] = useState(null);

  const [showBlackScreen, setShowBlackScreen] = useState(false);

  const resizeWindow = () => {
    console.log("SUUNTA", DB.orientation === 90);
    const screenSize =
      DB?.orientation === 90 || DB?.orientation === -90
        ? {
            width: window.innerHeight,
            heigth: window.innerWidth,
          }
        : {
            width: window.innerWidth,
            heigth: window.innerHeight,
          };

    setScreenSize(screenSize);
  };

  useEffect(() => {
    console.log('** MASTER EFF ***')
    let interval;

    let listener;

    if (!networkStatus) {
      console.log("** SET OFFLINE DATA **");
      const data = JSON.parse(localStorage.getItem("DB_offline")) || {};
      dispatch(actionSetDisplayData(data));
    }

    if (usePreview) {
      // ESIKATSELU
      console.log("* PRE *", preDB);
      dispatch(actionSetDisplayData(preDB));
    } else {
      // OIKEA NÄTTÖ (ei esikatselu)
      dispatch(actionToggleAppbar(false));

      if (!deviceId) {
        let deviceId = `${uuidv4()}+${Date.now()}`;
        setDeviceId(deviceId);

        localStorage.setItem(idName, deviceId);
      }

      console.log("* EFF FETCH **");
      dispatch(actionGetDBWithLink({ deviceId }));

      // 3min
      interval = setInterval(() => {
        // console.log(" * FETCH URL 2*");
        dispatch(actionCheckNeedToFetch({ deviceId, DB }));
      }, pollingDelay);

      listener = (event) => {
        const { key, keyCode } = event;

        if (keyCode === 27) {
          // ESC
          setAskPin((state) => !state);
        } else if (keyCode === 13) {
          // enter
          setEnterPressed(true);
        } else if (keyCode === 113) {
          // F2
          setDoSpeedTest((state) => !state);
        }
      };
      document.addEventListener("keydown", listener);
    }

    // tarvitaan esikatselussa
    return () => {
      document.removeEventListener("keydown", listener);
      dispatch(actionToggleAppbar(true));
      clearInterval(interval);
    };
  }, [deviceId]);

  const setActiveLayout2 = (data) => {
    dispatch(actionSetActiveLayout(data));
  };

  useEffect(() => {
    let interval;
    if (!loadingDB && DB) {
      const topbar = DB.topBar;
      const bottombar = DB.bottomBar;

      resizeWindow();
      window.addEventListener("resize", resizeWindow);

      localStorage.setItem("DB_offline", JSON.stringify(DB));

      if (topbar?.use) {
        setTopBar(topbar);
      }

      if (bottombar?.use) {
        setBottomBar(bottombar);
      }

      setActiveLayout(DB, setActiveLayout2, showBlackScreen);

      interval = setInterval(() => {
        console.log("** CHECK LAYOUT **");
        setActiveLayout(DB, setActiveLayout2, showBlackScreen);
      }, checkLayoutDelay);
    }

    return () => {
      clearInterval(interval);
      window.removeEventListener("resize", resizeWindow);
    };
  }, [DB, showBlackScreen]);

  useEffect(() => {
    // screenref kuvastaa aluetta jolla kortit on
    // sen ulkopuolella siis on ylä ja alapalkki
    if (screenRef?.current && screenSize && !loadingDB) {
      const screen = screenRef.current.style;
      const bottomHeight = bottomBar?.size || 0;
      const topHeight = topBar?.size || 0;
      screen.height = `${
        screenSize.heigth - bottomHeight - topHeight - 2 * padding
      }px`;
      screen.width = `${screenSize.width - 2 * padding}px`;
      screen.setProperty("--colGap", `${colGap}px`);
      screen.setProperty("--rowGap", `${rowGap}px`);

      // jos menee näytöstä yli voitaisiin korjata
      // ei ole ns käytössä
      if (rootRef?.current) {
        const root = rootRef.current.style;

        // root.setProperty("--padding", `${padding}px`);

        root.setProperty("--rotation", `${DB.orientation}deg`);
      }
    }
  }, [screenSize, topBar, bottomBar, loadingDB]);

  useEffect(() => {
    // jos kulmia on klikattu 7sek sisällä vähintään 4 kertaa molempia, kysytään pin
    let countdown;

    if (leftClickedCount > 0 || rightClickedCount > 0) {
      countdown = setTimeout(() => resetCornerClicks(), 7000);

      if (leftClickedCount > 2 && rightClickedCount > 2) {
        clearTimeout(countdown);
        setAskPin(true);
      }
    }

    return () => clearTimeout(countdown);
  }, [leftClickedCount, rightClickedCount]);

  const handleCornerClick = (position) => {
    if (position === "topLeft") {
      setLeftClickedCount((state) => (state += 1));
    } else if (position === "bottomRight") {
      setRightClickedCount((state) => (state += 1));
    }
  };

  const resetCornerClicks = () => {
    setLeftClickedCount(0);
    setRightClickedCount(0);
    setAskPin(false);
    setEnterPressed(false);
    setShowBlackScreen(false);
    setRightTopClickedCount(0);
  };

  const makeScreenBlack = () => {
    setRightTopClickedCount(rightTopClickedCount + 1);
    let timeout;
    if (rightTopClickedCount === 4) {
      setShowBlackScreen(true);

      const delay = 1000 * 60 * 5;
      timeout = setTimeout(() => {
        console.log("UN BLACK SCREEN");
        setShowBlackScreen(false);
        setRightTopClickedCount(0);

        clearTimeout(timeout);
      }, delay);
    } else if (rightTopClickedCount === 6) {
      //mustaa klikkaa 2x niin tulee takaisin
      setShowBlackScreen(false);
      setRightTopClickedCount(0);
      clearTimeout(timeout);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setStates((state) => ({ ...state, [name]: value }));
  };

  const enterIdManually = () => {
    // jos localstoragesta jostain syystä poistuu deviceID, voidaan antaa vanha id manuaalisesti
    if (states?.deviceId) {
      localStorage.setItem(idName, states?.deviceId);
      window.location.reload();
    }
  };

  if (!loadingDB && !DB) {
    return (
      <div className="DBrootDiv">
         {askPin && (
        <DBPinCode
          askPin={askPin}
          resetCornerClicks={resetCornerClicks}
          enterPressed={enterPressed}
          setEnterPressed={setEnterPressed}
          setDoSpeedTest={setDoSpeedTest}
          doSpeedTest={doSpeedTest}
          DB={DB}
        />
      )}
          <div className="centerDiv qrCodeDiv">
            <div className="qrCodeBox">
              <QRCode
                className="qrBox"
                size={420}
                style={{ maxWidth: "100%", width: "100%" }}
                value={`${protocol}//${hostname}/link-display?deviceId=${deviceId}`}
                viewBox={`0 0 420 420`}
                onClick={enterIdManually}
              />

              <FormControl fullWidth>
                <TextField
                  id="outlined-basic"
                  label="Device id"
                  variant="outlined"
                  onChange={handleChange}
                  name="deviceId"
                  defaultValue={deviceId}
                />
              </FormControl>
            </div>
          </div>
      </div>
    );
  }

  if (loadingDB) {
    return (
      <div className="DBrootDiv">
        <div className="centerDiv">
          <CircularProgress />
        </div>
      </div>
    );
  }

  //console.log("URL", screenSize);

  return (
    <Fragment>
      {askPin && (
        <DBPinCode
          askPin={askPin}
          resetCornerClicks={resetCornerClicks}
          enterPressed={enterPressed}
          setEnterPressed={setEnterPressed}
          setDoSpeedTest={setDoSpeedTest}
          doSpeedTest={doSpeedTest}
          DB={DB}
        />
      )}
      <div className="RootRoot">
        <div
          className="invisibleDiv-left"
          onClick={() => handleCornerClick("topLeft")}
        />
        <div
          className="invisibleDiv-right"
          onClick={() => handleCornerClick("bottomRight")}
        />

        {rightTopClickedCount < 5 && (
          <div
            className="invisibleDiv-topRight"
            onClick={() => makeScreenBlack()}
          />
        )}
        <div className="DBrootDiv" ref={rootRef}>
          {topBar ? (
            <div
              className="TopBar"
              style={{
                height: topBar.size,
                backgroundColor: topBar.backgroundColor,
                color: topBar.textColor,
                fontSize: topBar.fontSize,
              }}
            >
              <h2>
                {DB.topBar?.type === "clock"
                  ? FormatDate(dateAndTime, "HH:mm:ss")
                  : DB.topBar?.type === "date"
                  ? FormatDate(dateAndTime, "dd DD.MM.YYYY")
                  : DB.topBar?.type === "clockDate"
                  ? FormatDate(dateAndTime, "dd DD.MM HH:mm:ss")
                  : DB.topBar?.text}
              </h2>
            </div>
          ) : null}

          <div ref={screenRef} className="contentDiv">
            {activeLayout ? (
              <DBContent2 />
            ) : showBlackScreen ? (
              <div className="emptyDiv" onClick={() => makeScreenBlack()} />
            ) : null}
          </div>

          {bottomBar ? (
            <div
              className="BottomBar"
              style={{
                height: bottomBar.size,
                backgroundColor: bottomBar.backgroundColor,
                color: bottomBar.textColor,
                fontSize: bottomBar.fontSize,
              }}
            >
              <h2>
                {DB.bottomBar?.type === "clock"
                  ? FormatDate(dateAndTime, "HH:mm:ss")
                  : DB.bottomBar?.type === "date"
                  ? FormatDate(dateAndTime, "dd DD.MM.YYYY")
                  : DB.bottomBar?.type === "clockDate"
                  ? FormatDate(dateAndTime, "dd DD.MM HH:mm:ss")
                  : DB.bottomBar?.text}
              </h2>
            </div>
          ) : null}
        </div>

        {usePreview ? (
          <div className="usePreviewDiv">
            <Button variant="contained" color="primary" onClick={closePreview}>
              SULJE
            </Button>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

export default DBUrl2;
