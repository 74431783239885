import { useSelector } from "react-redux";
import DBCard from "../../components/cards/DBCard";

const DBContent2 = () => {
  const {
    activeLayout: { type, cards },
  } = useSelector((state) => state.DB);

  return (
    <div className="DBContent">
      <div className={`cardsDiv type-${type}`}>
        {cards.map((el, i) => (
          <DBCard type={type} data={el} key={i} />
        ))}
      </div>
    </div>
  );
};

export default DBContent2;
